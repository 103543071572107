import { MobileFormControlComponent } from './mobile-form-control/mobile-form-control.component';
import { MemberNumberFormControlComponent } from './member-number-form-control/member-number-form-control.component';
import { ZipCodeFormControlComponent } from './zipcode-form-control/zipcode-form-control.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxFormControlComponent } from './combobox-form-control/combobox-form-control.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NameFormControlComponent } from './name-form-control/name-form-control.component';
import { TrimDirective } from '../directives/trim.directive';
import { CapitalizeAllDirective } from '../directives/capitalize-all.directive';
import { OnlyLettersDirective } from '../directives/only-letters.directive';
import { DropDownFormControlComponent } from './dropdown-form-control/dropdown-form-control.component';
import { NgxMaskModule } from 'ngx-mask';
import { NumberFormControlComponent } from './number-form-control/number-form-control.component';
import { DateFormControlComponent } from './date-form-control/date-form-control.component';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NoSymbolsDirective } from '../directives/no-symbols.directive';
import { NameWithDigitsFormControlComponent } from './name-with-digits-form-control/name-with-digits-form-control.component';
import { InfoFormControlComponent } from './info-form-control/info-form-control.component';
import { EmailFormControlComponent } from './email-form-control/email-form-control.component';
import { PhoneFormControlComponent } from './phone-form-control/phone-form-control.component';
import { SncfFormControlComponent } from './sncf-form-control/sncf-form-control.component';
import { CheckboxListFormControlComponent } from './checkbox-list-form-control/checkbox-list-form-control.component';
import { MatListModule } from '@angular/material/list';
import { IbanFormControlComponent } from './iban-form-control/iban-form-control.component';
import { CheckboxFormControlComponent } from './checkbox-form-control/checkbox-form-control.component';
import { DateInputFormControlComponent } from './date-input-form-control/date-input-form-control.component';
import { PipesModule } from '../pipes/pipes.module';
import { DistributionFormControlComponent } from './distribution-form-control/distribution-form-control.component';
import { DistributionPercentFormControlComponent } from './distribution-percent-form-control/distribution-percent-form-control.component';
import { PasswordFormControlComponent } from './password-form-control/password-form-control.component';
import { NameAutocompleteFormControlComponent } from './name-autocomplete-form-control/name-autocomplete-form-control.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RadioGroupFormControlComponent } from './radio-group-form-control/radio-group-form-control.component';
import { NirFormControlComponent } from './nir-form-control/nir-form-control.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { AsyncIbanValidatorDirective } from './iban-form-control/iban-validator.directive';
import { MifadvisorCodeFormControlComponent } from './mif-advisor-code-form-control/mif-advisor-code-form-control.component';

@NgModule({
  declarations: [
    TrimDirective,
    OnlyLettersDirective,
    NoSymbolsDirective,
    CapitalizeAllDirective,
    ComboBoxFormControlComponent,
    NameFormControlComponent,
    NameWithDigitsFormControlComponent,
    DropDownFormControlComponent,
    NumberFormControlComponent,
    DateFormControlComponent,
    ZipCodeFormControlComponent,
    InfoFormControlComponent,
    EmailFormControlComponent,
    PhoneFormControlComponent,
    SncfFormControlComponent,
    CheckboxListFormControlComponent,
    IbanFormControlComponent,
    CheckboxFormControlComponent,
    DateInputFormControlComponent,
    MemberNumberFormControlComponent,
    MobileFormControlComponent,
    DistributionFormControlComponent,
    DistributionPercentFormControlComponent,
    PasswordFormControlComponent,
    NameAutocompleteFormControlComponent,
    RadioGroupFormControlComponent,
    NirFormControlComponent,
    AsyncIbanValidatorDirective,
    MifadvisorCodeFormControlComponent
  ],
  imports: [
    PipesModule,
    FontAwesomeModule,
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMatDatetimePickerModule,
    NgxMaskModule.forRoot(),
    MatAutocompleteModule
  ],
  exports: [
    ComboBoxFormControlComponent,
    NameFormControlComponent,
    NameWithDigitsFormControlComponent,
    DropDownFormControlComponent,
    NumberFormControlComponent,
    DateFormControlComponent,
    ZipCodeFormControlComponent,
    InfoFormControlComponent,
    EmailFormControlComponent,
    PhoneFormControlComponent,
    SncfFormControlComponent,
    CheckboxListFormControlComponent,
    IbanFormControlComponent,
    CheckboxFormControlComponent,
    DateInputFormControlComponent,
    MemberNumberFormControlComponent,
    MobileFormControlComponent,
    DistributionFormControlComponent,
    DistributionPercentFormControlComponent,
    PasswordFormControlComponent,
    NameAutocompleteFormControlComponent,
    RadioGroupFormControlComponent,
    NirFormControlComponent,
    MifadvisorCodeFormControlComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['DD/MM/YYYY']
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class FormModule {}
