import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProgressBarService } from '@services/progress-bar.service';
import { collections } from '@constants/collections';
import { constants } from '@constants/constants';
import { ComboBoxFormControl } from '@form/combobox-form-control/combobox-form-control';
import { DateFormControl } from '@form/date-form-control/date-form-control';
import { DropDownFormControl } from '@form/dropdown-form-control/dropdown-form-control';
import { NameFormControl } from '@form/name-form-control/name-form-control';
import { ZipCodeFormControl } from '@form/zipcode-form-control/zipcode-form-control';
import { WsCRMClientService } from '@shared/webapi/services/ws-crmclient.service';
import { Router } from '@angular/router';
import { Pays, Informations, Motifs } from '@webapi/MIF.Subscription.Parrot';
import { Profile, ProfileDocument, Subscription } from '@webapi/MIF.Subscription.WebApi';
import { CustomerService } from '@webapi/services/customer.service';
import { getMyDetailsModel } from '@extensions/user-data-extensions';
import { getDobLimits, getPoliticallyPersonDataModel, isCems, isGfd, isGpa, isPeri, isSameDate } from '@extensions/extensions';
import { SettingsService } from '@webapi/services/settings.service';
import { SubscriptionHandler } from '@components/subscriptionHandler';
import moment from 'moment';
import { DobLimits, WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { ProfileStepName } from '@models/profile-step-name';
import { NirFormControl } from '@form/nir-form-control/nir-form-control';
import { MyDetailsDataService } from '@services/my-details.data.service';
import { countries } from '@constants/countries';
import { LabelTextPipe } from '@pipes/label-text.pipe';
import { FileUploaderStatus } from '@shared/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-my-details',
  templateUrl: './my-details.component.html',
  styleUrls: ['./my-details.component.scss']
})
export class MyDetailsComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public countries: Pays[];
  public nationalities: Informations[];
  public civilities: Informations[];
  public familySituations: Informations[];
  public matrimonialRegimes: Informations[];
  public franceId: any;
  public franceNationalityId: any;
  public politicallyPersonTooltipText: string;
  public nirTooltipText: string;
  public firstDocsUploaderFiles: File[];
  public firstDocsRestoredUploaderFiles: File[];
  public politicallyPersonTypes: Motifs[];
  public familySituationControl: DropDownFormControl;
  public matrimonialRegimeControl: DropDownFormControl;
  public clientProfile: Profile;
  public civilityControl: ComboBoxFormControl;
  public politicallyPersonCommonTypeNumberControl: DropDownFormControl;
  public politicallyPersonTypeNumberControl: DropDownFormControl;
  public subscriptionConfiguration: Subscription;
  public dobControl: DateFormControl;
  public subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public dobValueIsNotInRangeLimit: boolean;
  public checkedDobValue: any;
  public dobMinDateValidation: Date;
  public dobMaxDateValidation: Date;
  public dobMaxDate: Date;
  public dobLimits: DobLimits;
  isCems = isCems();
  isGpa = isGpa();
  isGfd = isGfd();
  isPeri = isPeri();
  public ProfileStepName = ProfileStepName;
  public firstIdentificationDocumentTypeControl: DropDownFormControl;
  public nirControl: NirFormControl;
  public countryIdsToBeSkipped: number[] = [250, 258, 260]; // FRANCE, POLYNESIE FRANCAISE, TERRES AUSTRALES FRANCAISES
  public countryOfBirthControl: DropDownFormControl;
  public postalCodeOfBirthControl: ZipCodeFormControl;
  public isNirValidatorError: boolean = false;
  public firstDocsUploaderStatus: any;

  constructor(
    private router: Router,
    private wsCRMClientService: WsCRMClientService,
    private progressBarService: ProgressBarService,
    private customerService: CustomerService,
    private settingsService: SettingsService,
    private wsReferentielMetierService: WsReferentielMetierService,
    public myDetailsDataService: MyDetailsDataService,
    public labelTextPipe: LabelTextPipe,
    public bsModalService: BsModalService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initTranslations();
    this.progressBarService.sendStep(constants.steps.myBeneficiaries.countOfTheSteps);
    await this.fetchInitData();
    this.initNewFormGroup();
    this.initControls();
    this.initSubscriptions();
    await this.restoreAnswersOnForm();
  }

  initTranslations(): void {
    this.politicallyPersonTooltipText = this.labelTextPipe.transform('Identity.FormPoliticallyExposedHelpContent');
    this.nirTooltipText = this.labelTextPipe.transform('Identity.NirTooltipText');
  }

  async fetchInitData(): Promise<void> {
    this.subscriptionConfiguration = await this.settingsService.getSubscriptionConfiguration();
    this.clientProfile = await this.customerService.getUserData();
    this.countries = await this.wsCRMClientService.getAllCountries();
    this.nationalities = await this.wsCRMClientService.getNationalities();
    this.politicallyPersonTypes = await this.wsCRMClientService.getPoliticallyPersonTypes();
    this.civilities = await this.wsCRMClientService.getCivilities();
    this.familySituations = await this.wsCRMClientService.getFamilySituations();
    this.matrimonialRegimes = await this.wsCRMClientService.getMatrimonialRegimes();
    this.dobLimits = await this.wsReferentielMetierService.getDobLimits();

    this.countries.forEach((item: any) => {
      if (item['alpha3'] === this.subscriptionConfiguration.defaultCountryCode) {
        this.franceId = item['id'];
      }
    });

    this.nationalities.forEach((item: any) => {
      if (item['code'] === this.subscriptionConfiguration.defaultNationalityCode) {
        this.franceNationalityId = item['id'];
      }
    });

    this.initDobLimits();
  }

  initDobLimits(): void {
    const limits = getDobLimits(this.dobLimits);

    this.dobMinDateValidation = limits.dobMinDateValidation;
    this.dobMaxDateValidation = limits.dobMaxDateValidation;
    this.dobMaxDate = limits.dobMaxDate;
  }

  initControls(): void {
    this.nirControl = this.form.get('nir') as NirFormControl;
    this.civilityControl = this.form.get('civility') as ComboBoxFormControl;
    this.familySituationControl = this.form.get('familySituation') as DropDownFormControl;
    this.matrimonialRegimeControl = this.form.get('matrimonialRegime') as DropDownFormControl;
    this.politicallyPersonCommonTypeNumberControl = this.form.get('politicallyPersonCommonTypeNumber') as DropDownFormControl;
    this.politicallyPersonTypeNumberControl = this.form.get('politicallyPersonTypeNumber') as DropDownFormControl;
    this.countryOfBirthControl = this.form.get('countryOfBirth') as DropDownFormControl;
    this.postalCodeOfBirthControl = this.form.get('postalCodeOfBirth') as ZipCodeFormControl;
    this.dobControl = this.form.get('dob') as DateFormControl;
    this.firstIdentificationDocumentTypeControl = this.form.get('firstIdentificationDocumentType') as DropDownFormControl;
  }

  initSubscriptions(): void {
    this.subscriptionHandler.subscriptions = this.dobControl.statusChanges.subscribe((status: string) => {
      const dobValue = this.dobControl.value;

      if (status === constants.validStatus && dobValue) {
        if (this.checkedDobValue !== dobValue) {
          this.checkedDobValue = dobValue;
          if (moment(dobValue).isBetween(this.dobMinDateValidation, this.dobMaxDateValidation) || isSameDate(dobValue, this.dobMinDateValidation)) {
            this.dobValueIsNotInRangeLimit = false;
          } else {
            this.dobValueIsNotInRangeLimit = true;
          }
        }
      } else {
        this.dobValueIsNotInRangeLimit = false;
      }
    });

    this.subscriptionHandler.subscriptions = this.nirControl.statusChanges.subscribe((status: string) => {
      this.isNirValidatorError = this.nirControl?.errors?.nirValidator === true;
    });

    this.subscriptionHandler.subscriptions = this.form.valueChanges.subscribe((value: any) => {
      this.myDetailsDataService.initData({
        form: this.form.getRawValue(),
        civilities: this.civilities
      });

      setTimeout(() => {
        this.nirControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      });
    });

    this.subscriptionHandler.subscriptions = this.countryOfBirthControl.valueChanges.subscribe((countryId: number) => {
      const countriesDataMif: Pays[] = this.countries;
      const countriesDataJson: any = countries;
      const selectedAlpha3 = countriesDataMif.find(item => {
        return item.id === countryId;
      })?.alpha3;

      if (!selectedAlpha3) {
        this.postalCodeOfBirthControl.enable();
        return;
      }

      if (
        this.countryIdsToBeSkipped.findIndex((id: number) => {
          return id === countryId;
        }) !== -1
      ) {
        this.postalCodeOfBirthControl.enable();
        return;
      }

      const inseeCode = countriesDataJson[selectedAlpha3]?.inseeCode;

      if (inseeCode) {
        this.postalCodeOfBirthControl.patchValue(inseeCode + '');
        this.postalCodeOfBirthControl.disable();

        if (this.nirControl.value) {
          setTimeout(() => {
            this.nirControl.markAsTouched();
            this.nirControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
          });
        }
      } else {
        this.postalCodeOfBirthControl.enable();
      }
    });
  }

  initNewFormGroup(): void {
    const isMember = this.clientProfile.isMember !== undefined && this.clientProfile.isMember;

    this.form = new FormGroup({
      civility: new ComboBoxFormControl(true, isMember, {
        collection: this.civilities,
        valuePropertyName: constants.informationValueField,
        keyPropertyName: constants.referenceKeyField
      }),
      commonName: new NameFormControl(true, isMember, { capitalizeAll: true, maxLength: constants.maxLength.lastName }),
      birthName: new NameFormControl(true, isMember, { capitalizeAll: true, maxLength: constants.maxLength.birthName }),
      firstName: new NameFormControl(true, isMember, { capitalizeAll: true, maxLength: constants.maxLength.firstName }),
      dob: new DateFormControl(true, isMember),
      countryOfBirth: new DropDownFormControl(
        true,
        isMember,
        {
          collection: this.countries,
          valuePropertyName: constants.countryDescriptionField,
          keyPropertyName: constants.countryKeyField
        },
        {
          defaultValue: this.franceId,
          hideErrorMark: true
        }
      ),
      postalCodeOfBirth: new ZipCodeFormControl(true, isMember, { hideSuccessMark: true }),
      cityOfBirth: new NameFormControl(true, isMember, { capitalizeAll: true }),
      nationality: new DropDownFormControl(
        true,
        false,
        {
          collection: this.nationalities,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          defaultValue: this.franceNationalityId,
          hideErrorMark: true
        }
      ),
      nir: new NirFormControl(true, false, {
        myDetailsDataService: this.myDetailsDataService
      }),
      familySituation: new DropDownFormControl(
        true,
        false,
        {
          collection: this.familySituations,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      matrimonialRegime: new DropDownFormControl(
        false,
        false,
        {
          collection: this.matrimonialRegimes,
          valuePropertyName: constants.informationValueField,
          keyPropertyName: constants.referenceKeyField
        },
        {
          hideErrorMark: true
        }
      ),
      politicallyPerson: new ComboBoxFormControl(true, false, {
        collection: collections.yesNo,
        valuePropertyName: 'description'
      }),
      politicallyPersonCommonTypeNumber: new DropDownFormControl(
        true,
        false,
        {
          collection: this.politicallyPersonTypes,
          valuePropertyName: 'libelle',
          keyPropertyName: 'id',
          disableChooseOption: true
        },
        {
          requiredErrorMessage: collections.messages.politicallyPersonTypeNumberError,
          hideSuccessMark: true,
          hideErrorMark: true,
          matOptionAutoHeight: true
        }
      ),
      politicallyPersonTypeNumber: new DropDownFormControl(
        true,
        false,
        {
          collection: [],
          valuePropertyName: 'libelle',
          keyPropertyName: 'id',
          disableChooseOption: true
        },
        {
          requiredErrorMessage: collections.messages.politicallyPersonTypeNumberError,
          hideSuccessMark: true,
          hideErrorMark: true,
          matOptionAutoHeight: true
        }
      ),
      firstIdentificationDocumentType: new DropDownFormControl(
        false,
        false,
        {
          collection: collections.firstIdentificationDocumentTypes,
          valuePropertyName: 'description',
          keyPropertyName: 'id',
          disableChooseOption: true
        },
        {
          hideSuccessMark: true,
          hideErrorMark: true,
          hideErrorMessages: true,
          defaultValue: 'CNI',
          matOptionAutoHeight: true
        }
      )
    });
  }

  familySituationChanged(event: any): void {
    const status = this.familySituations.filter((item: Informations) => {
      return item.id === event.value;
    });

    this.matrimonialRegimeControl.patchValue(null);

    if (status[0]?.libelleLong === constants.mariedAnswer) {
      this.matrimonialRegimeControl.markAsRequred();
    } else {
      this.matrimonialRegimeControl.markAsNotRequred();
    }
  }

  get isMaried(): boolean {
    if (!this.familySituationControl.value) {
      return false;
    }

    const status = this.familySituations.filter((item: Informations) => {
      return item.id === this.familySituationControl.value;
    });

    return status[0]?.libelleLong === constants.mariedAnswer;
  }

  async restoreAnswersOnForm(): Promise<void> {
    if (this.clientProfile) {
      const dataModel = getMyDetailsModel(this.clientProfile);

      dataModel.countryOfBirth = dataModel.countryOfBirth ? dataModel.countryOfBirth : this.franceId;
      dataModel.nationality = dataModel.nationality ? dataModel.nationality : this.franceNationalityId;

      this.onPoliticallyPersonChanges(dataModel.politicallyPerson);

      if (dataModel.politicallyPerson === constants.yesAnswer) {
        const model = getPoliticallyPersonDataModel(this.politicallyPersonTypes, dataModel.politicallyPersonTypeNumber);

        if (model.commonTypeId) {
          dataModel.politicallyPersonCommonTypeNumber = model.commonTypeId;

          const selectedCommonType = this.politicallyPersonTypes.filter(item => {
            return item.id === model.commonTypeId;
          })[0];

          this.politicallyPersonTypeNumberControl.setCollection(selectedCommonType?.sousmotifs);
        }
      }

      this.form.patchValue(dataModel);
    }

    this.restoreFiles();
  }

  restoreFiles(): void {
    if (this.clientProfile.profileDocuments?.length > 0) {
      this.clientProfile.profileDocuments.forEach((item: ProfileDocument) => {
        if (item.typeId === collections.profileDocuments.firstIdentificationDocument.typeId) {
          const file = new File([''], item.file.fileName, {});

          if (!this.firstDocsRestoredUploaderFiles) {
            this.firstDocsRestoredUploaderFiles = [];
          }

          this.firstDocsRestoredUploaderFiles.push(file);
        }
      });
    }
  }

  onPoliticallyPersonCommonTypeNumberChanges(event: any): void {
    this.politicallyPersonTypeNumberControl.patchValue(null);
    const commonTypeId = event.value;

    const selectedCommonType = this.politicallyPersonTypes.filter(item => {
      return item.id === commonTypeId;
    })[0];

    this.politicallyPersonTypeNumberControl.setCollection(selectedCommonType?.sousmotifs);
  }

  onPoliticallyPersonChanges(event: any): void {
    if (event === constants.yesAnswer) {
      this.politicallyPersonCommonTypeNumberControl.markAsRequred();
      this.politicallyPersonTypeNumberControl.markAsRequred();
    } else {
      this.politicallyPersonCommonTypeNumberControl.markAsNotRequred();
      this.politicallyPersonTypeNumberControl.markAsNotRequred();
      this.politicallyPersonCommonTypeNumberControl.patchValue(null);
      this.politicallyPersonTypeNumberControl.patchValue(null);
    }

    this.form.updateValueAndValidity();
  }

  firstDocsUploaderStatusChanged(status: any): void {
    this.firstDocsUploaderStatus = status;
  }

  firstDocsUploaderChanged(files: File[]): void {
    this.firstDocsUploaderFiles = files;
    this.firstIdentificationDocumentTypeControl.markRequiredOrNot(files?.length > 0);
    if (files?.length > 0) {
      this.firstIdentificationDocumentTypeControl.markAsTouched();
    }
  }

  get isPoliticallyPerson(): boolean {
    const politicallyPersonControl = this.form.get('politicallyPerson') as ComboBoxFormControl;
    return politicallyPersonControl.value === constants.yesAnswer;
  }

  get isPoliticallyPersonCommonType(): boolean {
    const politicallyPersonControl = this.form.get('politicallyPersonCommonTypeNumber') as DropDownFormControl;
    return !!politicallyPersonControl.value;
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.valid) {
      this.processSuccess();
    }
  }

  async processSuccess(): Promise<void> {
    const model = this.form.getRawValue() as MyDetailsFormModel;

    model.firstIdentificationDocumentFiles = this.firstDocsUploaderFiles;

    const userId = await this.customerService.updateMyDetailsModel(model, this.bsModalService, this.labelTextPipe);

    if (userId !== null) {
      this.router.navigate(['my-details/contacts'], { queryParams: { userId } });
    }
  }

  get isFormDisabled(): boolean {
    return this.form.invalid || this.dobValueIsNotInRangeLimit || this.firstDocsUploaderStatus === FileUploaderStatus.INVALID;
  }

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }
}

export interface PoliticallyPersonDataModel {
  commonTypeId: number;
  typeId: number;
}

export interface MyDetailsFormModel {
  civility: number;
  nir?: string;
  commonName: string;
  birthName: string;
  firstName: string;
  dob: Date;
  countryOfBirth: number;
  postalCodeOfBirth: string;
  cityOfBirth: string;
  nationality: number;
  familySituation: number;
  matrimonialRegime: number;
  politicallyPerson: string;
  politicallyPersonCommonTypeNumber?: number;
  politicallyPersonTypeNumber: number;
  firstIdentificationDocumentType: any;
  firstIdentificationDocumentFiles?: File[];
}
