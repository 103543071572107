<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.MyContacts" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="row mt-4">
  <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
    <p *ngIf="isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitlePeri' | labelText"></p>
    <p *ngIf="!isPeri" class="main-intro text-center" [innerHtml]="'COMMON.TitleNotPeri' | labelText"></p>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="form" class="mb-5">
  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">
                {{ 'IDENTITY.Identity' | labelText }}
                <a class="red-link" href="javascript:void(0)" (click)="goToMyDetails()">
                  {{ 'Common.Modify' | labelText }}
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'ADDRESS.ContactDetails' | labelText }}</h1>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col">
              <span class="like-speech-title"> {{ 'ADDRESS.Address' | labelText }} </span>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
              <app-dropdown-form-control [parentForm]="form" [controlName]="'country'" [title]="'Contact_Information.Country' | labelText"> </app-dropdown-form-control>
            </div>
          </div>

          <app-view-offer [clientProfile]="clientProfile" [pageName]="constants.pages.address.name"></app-view-offer>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-3">
              <app-zipcode-form-control [parentForm]="form" [controlName]="'postalCode'" [title]="'Contact_Information.PostalCode' | labelText" (focusout)="postalCodeChange($event)">
              </app-zipcode-form-control>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-5">
              <app-name-autocomplete-form-control
                [parentForm]="form"
                [controlName]="'city'"
                [title]="'Contact_Information.City' | labelText"
                [autocompleteOptions]="cityAutocompleteOptions"
                (focusout)="cityChange($event)"
              >
              </app-name-autocomplete-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <app-name-autocomplete-form-control
                [parentForm]="form"
                [controlName]="'nameOfTheRoadAndPlace'"
                [title]="'Contact_Information.NameOfTheRoadAndPlace' | labelText"
                [autocompleteOptions]="adressAutocompleteOptions"
              >
              </app-name-autocomplete-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <app-name-with-digits-form-control [parentForm]="form" [controlName]="'additionalAddress'" class="dotsWhenNoPlace" [title]="'Contact_Information.AdditionalAddress' | labelText">
              </app-name-with-digits-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <app-name-with-digits-form-control [parentForm]="form" [controlName]="'additionalAddress2'" class="dotsWhenNoPlace" [title]="'Contact_Information.AdditionalAddress2' | labelText">
              </app-name-with-digits-form-control>
            </div>
          </div>

          <div class="row mb-6">
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <app-name-with-digits-form-control [parentForm]="form" [controlName]="'locality'" class="dotsWhenNoPlace" [title]="'Contact_Information.Locality' | labelText">
              </app-name-with-digits-form-control>
            </div>
          </div>

          <div class="row with-speech">
            <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
              <span class="icon icon-aside"></span>
            </div>
            <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
              <div class="speech">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                    <span> {{ 'ADDRESS.Proof' | labelText }} </span>
                    <p class="fs-14">{{ 'ADDRESS.TypeProof' | labelText }}</p>
                    <p class="fs-14">
                      <b class="text-italic"> {{ 'BANK_DETAILS.RIBContent1' | labelText }} </b>
                      <br />
                      {{ 'BANK_DETAILS.RIBContent2' | labelText }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                    <app-file-uploader
                      [files]="addressConfirmationRestoredDocumentFiles"
                      (onChange)="contactsDocsUploaderChanged($event)"
                      (onStatusChange)="contactsDocsUploaderStatusChanged($event)"
                    ></app-file-uploader>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-7 col-md-6 col-lg-8">
              <app-email-form-control [parentForm]="form" [controlName]="'email'" [title]="'ADDRESS.Email' | labelText" (focusout)="emailChange($event)"> </app-email-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4">
              <app-mobile-form-control [parentForm]="form" class="error-new-row" [controlName]="'mobilePhone'" [title]="'COMMON.MobileNumber' | labelText"> </app-mobile-form-control>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
              <span class="box-separator"> {{ 'COMMON.And/or' | labelText }} </span>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4">
              <app-phone-form-control [parentForm]="form" class="error-new-row" [controlName]="'landLinePhone'" [title]="'COMMON.PhoneNumber' | labelText"> </app-phone-form-control>
            </div>
          </div>

          <div class="row mt-5 mt-0-xs">
            <div class="col txt-mentions">
              {{ 'COMMON.AddPhoneNumberForFollowUp' | labelText }}
            </div>
          </div>

          <div class="row text-center mt-5">
            <div class="col">
              <div class="valider-button-block">
                <button type="submit" class="btn btn-blue valider" [disabled]="isFormDisabled">
                  {{ 'Common.Validate' | labelText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card disabled">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'SITUATION.ProAndPatrimonialSituation' | labelText }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card disabled">
    <div class="row">
      <div class="col-12 text-left">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h1 class="section-title">{{ 'BANK_DETAILS.YourBankDetails' | labelText }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
