import { constants } from './shared/constants/constants';
import { AppRoutingModule } from '../../mif/app/app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { MembershipCheckComponent } from './wizard/welcome/membership-check/membership-check.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResidenceQuestionsComponent } from './wizard/welcome/residence-questions/residence-questions.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ProfileStepsComponent } from './my-profile/profile-steps/profile-steps.component';
import { API_BASE_URL, CustomerProfileClient, LabelsClient, LookupsClient, OffersClient, ReportingClient, SettingsClient } from './shared/webapi/MIF.Subscription.WebApi';
import { Client, PARROT_API_BASE_URL } from './shared/webapi/MIF.Subscription.Parrot';
import { MyBeneficiariesComponent } from './my-beneficiaries/my-beneficiaries.component';
import { MyDetailsComponent } from './my-details/my-details.component';
import { SignatureComponent } from './signature/signature.component';
import { MyContractComponent } from './wizard/my-contract/my-contract.component';
import { HttpClientModule, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProfileSummaryComponent } from './my-profile/profile-summary/profile-summary.component';
import { ChartsModule } from 'ng2-charts';
import { TooltipModule } from 'primeng/tooltip';
import { ClauseNominativeComponent } from './my-beneficiaries/clause-nominative/clause-nominative.component';
import { ClauseLibreComponent } from './my-beneficiaries/clause-libre/clause-libre.component';
import { FormModule } from './shared/form/form.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { AnswersDataService } from './services/answers-data.service';
import { ProgressBarService } from './services/progress-bar.service';
import { LabelsDataService } from './services/labels-data.service';
import { ContactsComponent } from './my-details/contacts/contacts.component';
import { FinancialSituationComponent } from './my-details/financial-situation/financial-situation.component';
import { BankDetailsComponent } from './my-details/bank-details/bank-details.component';
import { WsReferentielMetierService } from './shared/webapi/services/ws-referentiel-metier.service';
import { WsCRMClientService } from './shared/webapi/services/ws-crmclient.service';
import { WsSepaliaService } from './shared/webapi/services/ws-sepalia.service';
import { WsCrmQuestionnaireService } from './shared/webapi/services/ws-crm-questionnaire.service';
import { CustomerService } from './shared/webapi/services/customer.service';
import { WsPolicesService } from './shared/webapi/services/ws-polices.service';
import { PhoneConfirmationComponent } from './phone-confirmation/phone-confirmation.component';
import localeFr from '@angular/common/locales/fr';
import { PipesModule } from './shared/pipes/pipes.module';
import { WsGetPolices } from './shared/webapi/services/ws-get-polices.service';
import { FinishComponent } from './finish/finish.component';
import { SpinnerService } from './services/spinner.service';
import { SpinnerInterceptor } from './shared/interceptors/spinner.interceptor';
import { WsCoordonneesBancairesService } from './shared/webapi/services/ws-coordonnees-bancaires.service';
import { DistributionComponent } from './wizard/my-contract/distribution/distribution.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { Router, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { MainPageComponent } from './main-page/main-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { WsDqeService } from './shared/webapi/services/ws-dqe.service';
import { HealthQuestionnaireComponent } from './gpa/components/health-questionnaire/health-questionnaire.component';
import { SignatureGpaPlanComponent } from './signature/gpa/signature-gpa-plan/signature-gpa-plan.component';
import { SignatureGfdPlanComponent } from './signature/gfd/signature-gfd-plan/signature-gfd-plan.component';
import { SettingsService } from './shared/webapi/services/settings.service';
import { environment } from '../environments/environment';
import { AdminComponent } from 'projects/mif-admin/app/admin.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthGuard } from 'projects/mif-admin/app/auth/auth.guard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OffersService } from './shared/webapi/services/offers.service';
import { LookupsService } from './shared/webapi/services/lookups.service';
import { PublicAuthInterceptor } from './shared/interceptors/public-auth.interceptor';
import { TokenStorageService } from './shared/auth/token-storage.service';
import { AuthService } from 'projects/mif-admin/app/auth/auth.service';
import { AuthInterceptor } from 'projects/mif-admin/app/auth/auth.interceptor';
import { ResumeCheckComponent } from './wizard/welcome/resume-check/resume-check.component';
import { SubscriptionGuard } from '@shared/auth/subscription.guard';
import { SubscriptionTimeEndComponent } from './wizard/welcome/subscription-time-end/subscription-time-end.component';
import { ReportingService } from './shared/webapi/services/reporting.service';
import { LazyLoadingScriptService } from './services/lazy-loading-script.service';
import { AppConfigService } from './services/app.config.service';
import { PieDistributionComponent } from './wizard/my-contract/pie-distribution/pie-distribution.component';
import { DebounceDirective } from './shared/directives/debounce-click.directive';
import { AdermConfirmationComponent } from './my-profile/modals/aderm-confirmation/aderm-confirmation.component';
import { FilesUploadingInterceptor } from '@shared/interceptors/files-uploading.interceptor';
import { SubscriptionCompleteComponent } from './wizard/welcome/subscription-complete/subscription-complete.component';

const routes: Routes = [
  { path: '', data: { name: '', roles: ['cems'] }, loadChildren: () => import('../bundles/cems/cems.module').then(m => m.CemsModule) },
  { path: '', data: { name: '', roles: ['gpa'] }, loadChildren: () => import('../bundles/gpa/gpa.module').then(m => m.GpaModule) },
  { path: '', data: { name: '', roles: ['gfd'] }, loadChildren: () => import('../bundles/gfd/gfd.module').then(m => m.GfdModule) },
  { path: '', data: { name: '', roles: ['peri'] }, loadChildren: () => import('../bundles/peri/peri.module').then(m => m.PeriModule) },
  { path: '', data: { name: '', roles: ['cims'] }, loadChildren: () => import('../bundles/cims/cims.module').then(m => m.CimsModule) },
  { path: '', data: { name: '', roles: ['admin'] }, loadChildren: () => import('../../mif-admin/app/admin.module').then(m => m.AdminModule) }
];

registerLocaleData(localeFr);

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

export function AppConfigurationFactory(appConfigService: AppConfigService) {
  return () => appConfigService.init();
}

function GoogleTagManagerId(appConfigService: AppConfigService) {
  return appConfigService.googleTagManagerId;
}

function AdviceId(appConfigService: AppConfigService) {
  return appConfigService.adviceId;
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    MainPageComponent,
    HeaderComponent,
    FooterComponent,
    MembershipCheckComponent,
    ResidenceQuestionsComponent,
    MyProfileComponent,
    AdermConfirmationComponent,
    ProfileStepsComponent,
    MyContractComponent,
    MyBeneficiariesComponent,
    MyDetailsComponent,
    SignatureComponent,
    ProfileSummaryComponent,
    ClauseNominativeComponent,
    ClauseLibreComponent,
    ContactsComponent,
    FinancialSituationComponent,
    BankDetailsComponent,
    PhoneConfirmationComponent,
    FinishComponent,
    DistributionComponent,
    PieDistributionComponent,
    HealthQuestionnaireComponent,
    SignatureGpaPlanComponent,
    SignatureGfdPlanComponent,
    ResumeCheckComponent,
    SubscriptionTimeEndComponent,
    SubscriptionCompleteComponent,
    DebounceDirective
  ],
  imports: [
    PipesModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    CommonModule,
    HttpClientModule,
    TooltipModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    MatCheckboxModule,
    ChartsModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    NgxCurrencyModule,
    SharedComponentsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatExpansionModule,
    MatAutocompleteModule
  ],
  exports: [],
  providers: [
    [{
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [
        Injector,
        WsCRMClientService,
        WsReferentielMetierService,
        LabelsDataService
      ],
      multi: true
    }],
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigurationFactory,
      deps: [AppConfigService],
      multi: true
    },
    { provide: 'googleTagManagerId', useFactory: GoogleTagManagerId, deps: [AppConfigService] },
    { provide: 'adviceId', useFactory: AdviceId, deps: [AppConfigService] },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: PARROT_API_BASE_URL,
      useValue: environment.parrotApiBaseUrl
    },
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FilesUploadingInterceptor,
      multi: true
    },
    LazyLoadingScriptService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: initAuthInterceptor,
      deps: [AuthService, TokenStorageService],
      multi: true
    },
    CurrencyPipe,
    AnswersDataService,
    ProgressBarService,
    LabelsDataService,
    TokenStorageService,
    SpinnerService,
    Client,
    LabelsClient,
    CustomerProfileClient,
    WsCRMClientService,
    WsReferentielMetierService,
    WsSepaliaService,
    WsCrmQuestionnaireService,
    CustomerService,
    WsPolicesService,
    WsGetPolices,
    WsCoordonneesBancairesService,
    WsDqeService,
    SettingsClient,
    SettingsService,
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    AuthService,
    AuthGuard,
    SubscriptionGuard,
    OffersService,
    OffersClient,
    LookupsService,
    LookupsClient,
    ReportingService,
    ReportingClient
  ],
  bootstrap: [environment.orgId !== constants.orgId.admin.value ? AppComponent : AdminComponent]
})

export class AppModule {
  constructor(private modalService: BsModalService) {
    this.modalService.config.keyboard = true;
    this.modalService.config.backdrop = 'static';
    this.modalService.config.ignoreBackdropClick = true;
  }
}

export function initApp(
  injector: Injector,
  wsCRMClientService: WsCRMClientService,
  wsReferentielMetierService: WsReferentielMetierService,
  labelsDataService: LabelsDataService)
  : () => Promise<void> {
  return () => {
    return new Promise<void>(async (resolve) => {
      const orgId = environment.orgId;
      const filteredRoutes = routes.filter(r => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);

      if (environment.orgId !== constants.orgId.admin.value) {
        await labelsDataService.initliaze();
        await wsCRMClientService.initialize();
        await wsReferentielMetierService.initialize();
      }

      resolve();
    });
  };
}

export function initAuthInterceptor(authService: AuthService, tokenStorageService: TokenStorageService): HttpInterceptor {
  switch (environment.orgId) {
    case constants.orgId.admin.value:
      return new AuthInterceptor(authService);
    default:
      return new PublicAuthInterceptor(tokenStorageService);
  }
}
