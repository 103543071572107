<app-i-advize *ngIf="clientProfile" [pageType]="ProfileStepName.PhoneConfirmation" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div>
  <form *ngIf="form">
    <div class="mt-5">
      <div class="card">
        <div class="row">
          <div class="col-12 text-left">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h1 class="section-title">{{ 'SIGNATURE.SignatureTitle' | labelText }}</h1>
                </div>
              </div>

              <div class="row text-left">
                <div class="col">
                  <p *ngIf="isPeri" class="txt-default mt-2 mb-4">
                    {{ 'SIGNATURE.TitlePeri' | labelText }}
                  </p>
                  <p *ngIf="!isPeri" class="txt-default mt-2 mb-4">
                    {{ 'SIGNATURE.TitleNotPeri' | labelText }}
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 mb-1">
                  <app-mobile-form-control class="error-new-row" [parentForm]="form" [controlName]="'mobilePhone'" [title]="'COMMON.MobileNumber' | labelText" (keydown.enter)="getSms($event)">
                  </app-mobile-form-control>
                </div>

                <div class="hidden-xs col-md-2 col-lg-1"></div>

                <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                  <button class="btn btn-blue get-sms" (click)="getSms($event)" [disabled]="form.controls['mobilePhone'].invalid">
                    {{ getSmsText }}
                  </button>
                </div>
              </div>

              <div class="row" *ngIf="smsRequested">
                <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 mt-2">
                  <app-name-with-digits-form-control class="error-new-row" [parentForm]="form" [controlName]="'sms'" [title]="'SMS'" (keydown.enter)="onSubmit($event)">
                  </app-name-with-digits-form-control>
                </div>
              </div>

              <div class="row mt-5" *ngIf="smsRequested">
                <div class="col-12 text-center">
                  <button type="submit" class="btn btn-blue valider" (click)="onSubmit($event)" [disabled]="form.invalid">
                    {{ 'Common.Validate' | labelText }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
