import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCapitalizeAll]'
})
export class CapitalizeAllDirective {

  constructor(private ngControl: NgControl) { }

  @HostListener('window:keyup', ['$event']) OnBlur() {
    const value = this.ngControl.control.value;

    if (value) {
      const arr: string[] = value.split(' ');
      const res: string[] = [];

      arr.forEach((word) => {
        res.push(word.toUpperCase());
      });

      this.ngControl.control.patchValue(res.join(' '));
    }
  }

}
