import { Injectable } from '@angular/core';
import { GetDocumentsFondsRequest, Client, Document } from '../../shared/webapi/MIF.Subscription.Parrot';

@Injectable({
  providedIn: 'root'
})
export class FundsService {
  private readonly regulatoryDocumentType = 8;

  constructor(private webApi: Client) { }

  async getFundRegulatoryDocument(fundId: number): Promise<Blob> {

    const request = new GetDocumentsFondsRequest({
      idFonds: String(fundId),
      lireDocuments: true,
      idTypeDocument: this.regulatoryDocumentType
    });

    const documentResponse = await this.webApi.getDocumentsFonds(request).toPromise();
    const preffix = 'DIC_Avenant_web';
    const documents = documentResponse.document;
    const match = documents?.find(d => d.libelle.startsWith(preffix));
    const document = match ?? documents[documents.length - 1];

    const response = await fetch(
      `data:application/pdf;base64,${document.fichierAttache}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.blob();
  }

  async openRegulatoryDocumentAsync(documents: Array<Document>, fundId: number): Promise<void> {
    const preffix = 'DIC_Avenant_web';
    const match = documents?.find(d => d.libelle.startsWith(preffix));
    const document = match ?? documents[documents.length - 1];

    const response = await fetch(
      `data:application/pdf;base64,${document.fichierAttache}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, '_blank');
  }

  private getRegulatoryDocument(documents: Array<Document>): Document {
    const preffix = 'DIC_Avenant_web';
    const result = documents?.find(d => d.libelle.startsWith(preffix));

    const base64 = fetch(
      `data:application/pdf;base64,${result.fichierAttache}`
    ).then(r => {
      let blob1: Blob;
      r.blob().then(b => {
        blob1 = b;
      });
    });

    const byteCharacters = atob(result.fichierAttache);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);

    const fileUrl1 = `data:application/pdf;base64,${result.fichierAttache}`;

    const link = document.createElement('a');
    link.href = fileUrl1;
    link.download = `${preffix}.pdf`;
    link.click();

    window.open(fileUrl1, '_blank');

    return result ?? documents[documents.length - 1];
  }
}


