import { SubscriptionHandler } from '@components/subscriptionHandler';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormArray, FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LabelsDataService } from '@services/labels-data.service';
import { ProgressBarService } from '@services/progress-bar.service';
import { AlertModalComponent, modalTypes } from '@components/modals/alert-modal/alert-modal.component';
import { collections } from '@constants/collections';
import { constants } from '@constants/constants';
import { getCurrentProductType, getRiskColor, isCems, isCims, isManualDistributionEqualsRecommendet, isManualDistributionFondsNamesEqualsRecommendet, isPeri } from '@extensions/extensions';
import { NumberFormControl } from '@form/number-form-control/number-form-control';
import { EuroPipe } from '@pipes/euro.pipe';
import { PerformancePipe } from '@pipes/performance.pipe';
import { Client, Fonds, GetFraisVersementRequest, GetReglesMetierProduitRequest, Produit3, Recommandation, Regle } from '@webapi/MIF.Subscription.Parrot';
import { Profile, ProfileFund } from '@webapi/MIF.Subscription.WebApi';
import '@extensions/profile.extensions';
import { RuleCodes } from '@extensions/fonds.extensions';
import { CustomerService } from '@webapi/services/customer.service';
import { ContratTransferableFourgousExtended, WsPolicesService } from '@webapi/services/ws-polices.service';
import { FundsService } from './funds.service';
import { FundType } from '@models/fund-type';
import { ConfirmationComponent } from '@components/modals/confirmation/confirmation.component';
import { WsReferentielMetierService } from '@webapi/services/ws-referentiel-metier.service';
import { ProfileStepName } from '@models/profile-step-name';
import { minAmountValidator } from '@validators/minAmountValidator';
import { zeroValidator } from '@validators/zeroValidator';
import { fractionLimitValidator } from '@validators/fractionLimitValidator';
import { FundControlModel } from '@models/fund-control-model';
import { ValidationError } from '@models/validation-error';
import { FundPerformance } from '@models/fund-performance';
import { FundRule } from '@models/fund-rule';
import { WsCrmQuestionnaireService } from '@webapi/services/ws-crm-questionnaire.service';
import { LabelTextPipe } from '@pipes/label-text.pipe';

@Component({
  selector: 'app-my-contract',
  templateUrl: './my-contract.component.html',
  styleUrls: ['./my-contract.component.scss']
})
export class MyContractComponent implements OnInit, OnDestroy {
  showRecommendationNotFollowedFundStep: boolean = false;
  showRecommendationNotFollowedRappelStep: boolean = false;
  showRecommendationNotFollowedRepartitionStep: boolean = false;
  mismatchCheckForm: FormGroup;
  initialPaymentAmountControl: NumberFormControl;
  reqularPaymentsControl: NumberFormControl;
  transferableContractAmountControl: NumberFormControl;
  freePaymentAmountControl: NumberFormControl;
  initialPaymentAmount: number;
  scheduledPaymentAmount: number;
  freePaymentAmount: number;
  transferableContracts: any;
  transferableContractAmount: number;
  isTransferableContractAmountTitle: string;
  history: History;
  collections: any = collections;
  scpiFilters = { activeId: 1 };
  ucFilters = { activeId: 5 };
  euroFilters = { activeId: 1 };
  constants: any = constants;
  recommandation: Recommandation;
  score: number;
  roundRate: number = 2;
  fractionRoundRate: number = 2;
  distributionTableValues: FundControlModel[] = []; // Contains raw (ie. not rounded) values for computation
  offersCount: number;
  ProfileStepName = ProfileStepName;
  userDataForViewOffer: Profile;
  subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();
  public isPeri = isPeri();
  public isCems = isCems();
  public isCims = isCims();
  readonly euroFundCode = 'FR0000000000';
  userProfile: Profile;
  maxEuroFundFraction: number;
  questions: any;
  notForceToSelectUC: boolean;

  faInfoCircle = faInfoCircle;
  fondTypeSCPI: number;
  fondTypeOther: number;
  fondTypeEuro: number;

  contractFormGroup = this.fb.group({
    fondsSCPI: this.fb.array([]),
    fondsOther: this.fb.array([]),
    fondsEuro: this.fb.array([]),
    distributionFonds: this.fb.array([])
  });

  currentStep: Step;
  stepType = Step;

  getRiskColor = getRiskColor;
  parseFloat = parseFloat;
  allFundsRules: Produit3[];
  rule27or28ValueIssue: boolean;
  rule27or28Value: string;

  constructor(
    private fb: FormBuilder,
    private webApi: Client,
    private fundsService: FundsService,
    private progressBarService: ProgressBarService,
    public customerService: CustomerService,
    private router: Router,
    private wsPolicesService: WsPolicesService,
    private euroPipe: EuroPipe,
    private performancePipe: PerformancePipe,
    private currencyPipe: CurrencyPipe,
    private bsModalService: BsModalService,
    private wsReferentielMetierService: WsReferentielMetierService,
    private labelsDataService: LabelsDataService,
    private wsCrmQuestionnaireService: WsCrmQuestionnaireService,
    private labelTextPipe: LabelTextPipe
  ) {}

  ngOnDestroy(): void {
    this.subscriptionHandler.unsubscribeAll();
  }

  async ngOnInit(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.myProfile.countOfTheSteps);
    await this.fetchInitData();
    this.currentStep = Step.FUNDS_SELECTION;
    const isGestionSousMandat = this.userProfile.managementTypeCode === constants.gestionSousMandat.id;

    if (history.state.mismatchCheck || isGestionSousMandat) {
      // step 2 as a first
      history.state.mismatchCheck = true;
      this.history = history;
      this.currentStep = Step.RAPPEL_VERSEMENTS;
      this.showRecommendationNotFollowedRappelStep = true;
      await this.initMismatchCheckStep();
    } else {
      if (this.userProfile.profileFunds?.length > 0) {
        if (history.state.fromRecommendation) {
          this.restoreAnswersOnFundsSelectionStep(); // step 1 as a first with data restoring
        } else {
          await this.initMismatchCheckStep();
          this.iniRepartitionStep(); // step 3 as a first
          await this.restoredistributionFonds(this.fondsSCPI);
          await this.restoredistributionFonds(this.fondsOther);
          await this.restoredistributionFonds(this.fondsEuro);
          this.restoredistributionFondsValues();
        }
      }
    }
  }

  async fetchInitData(): Promise<void> {
    this.userProfile = await this.customerService.getUserData();
    this.questions = await this.wsCrmQuestionnaireService.getQuestions();
    this.allFundsRules = await this.wsReferentielMetierService.getFundsRules();
    await this.fetchRecommandation();
    await this.getFunds();
  }

  async fetchRecommandation(): Promise<void> {
    const montantVerse = this.userProfile.initialPaymentAmount || 0; // initialPaymentAmount - it's one field for free payment amount and first payment amount
    const dateEstimationDepartRetraite = this.isPeri ? this.userProfile.retirementPlan.expectedRetirementDate : null;
    const flagDurabilite = this.userProfile.isQuestion12AnsweredYes(this.questions);
    const recommandationResponse = await this.wsReferentielMetierService.getRecommandation(
      this.userProfile.score,
      !!this.userProfile.transferContractCode,
      montantVerse,
      false,
      dateEstimationDepartRetraite,
      flagDurabilite
    );
    this.recommandation = recommandationResponse.recommandation;
  }

  get fondsSCPI(): FormArray {
    return this.contractFormGroup.get('fondsSCPI') as FormArray;
  }

  get fondsOther(): FormArray {
    return this.contractFormGroup.get('fondsOther') as FormArray;
  }

  get fondsEuro(): FormArray {
    return this.contractFormGroup.get('fondsEuro') as FormArray;
  }

  get fundsStepActive(): boolean {
    return this.currentStep === Step.FUNDS_SELECTION;
  }

  get fondsCount(): number {
    return this.fondsSCPI.length + this.fondsOther.length + this.fondsEuro.length;
  }

  get distributionFonds(): FormArray {
    return this.contractFormGroup.get('distributionFonds') as FormArray;
  }

  get scpiFundType(): number {
    return FundType.SCPI;
  }

  get otherFundType(): number {
    return FundType.Other;
  }

  get euroFundType(): number {
    return FundType.Euro;
  }

  get isRecommendationsFollowed(): boolean {
    return this.userProfile?.isRecommendationsFollowed;
  }

  get isInitialPaymentAmount(): boolean {
    return this.initialPaymentAmount !== undefined;
  }

  get isScheduledPaymentAmount(): boolean {
    return this.scheduledPaymentAmount !== undefined;
  }

  get isTransferableContractAmount(): boolean {
    return this.transferableContractAmount !== undefined;
  }

  get isFreePaymentAmount(): boolean {
    return this.freePaymentAmount !== undefined;
  }

  get isDistributionFondsControlsTooltip(): boolean {
    return this.distributionFonds?.controls?.length > 3;
  }

  get distributionFondsControlsTooltipText(): string {
    let str = '';

    if (this.distributionFonds?.controls?.length > 3) {
      this.distributionFonds?.controls?.forEach((c: any, index) => {
        if (index > 2) {
          str += `${c.value.fond.name} <br/>`;
        }
      });
    }

    return str;
  }

  get totalAmountToDistribute(): number {
    if (this.userProfile.isScheduledPaymentsOnly()) {
      return this.userProfile.scheduledPaymentAmount;
    }
    return (this.userProfile.initialPaymentAmount ?? 0) + (this.transferableContractAmount ?? 0);
  }

  get fundDistributionExlpanationLabelKey(): string {
    const isInitialPayment = !!this.initialPaymentAmount;
    const isScheduledPayment = !!this.userProfile.scheduledPaymentAmount;
    const isTransfer = !!this.transferableContractAmount;
    const isAnyScpiSelected = this.fondsSCPI.controls.some(c => c.get('selected').value === true);
    const isScheduledPaymentOnly = isScheduledPayment && !isInitialPayment;

    if (!isTransfer) {
      if (!isScheduledPayment) {
        return 'DISTRIBUTION.FundsDistributionExplanationFirstPayment';
      }

      if (!isAnyScpiSelected) {
        return 'DISTRIBUTION.FundsDistributionExplanationFirstAndScheduledPayment';
      }

      if (isScheduledPaymentOnly) {
        return 'DISTRIBUTION.FundsDistributionExplanationNotAvaliableForScheduledPayment';
      }

      if (isAnyScpiSelected && isScheduledPayment) {
        return 'DISTRIBUTION.FundsDistributionExplanationFirstPaymentWithoutScpi';
      }
    } else {
      if (!isScheduledPayment) {
        return 'DISTRIBUTION.FundsDistributionExplanationFirstPaymentWithTransfer';
      }

      if (!isAnyScpiSelected) {
        return 'DISTRIBUTION.FundsDistributionExplanationFirstAndScheduledPaymentWithTransfer';
      }

      if (isAnyScpiSelected && isScheduledPayment) {
        return 'DISTRIBUTION.FundsDistributionExplanationFirstPaymentWithTransferWithoutScpi';
      }
    }

    return '';
  }

  get validationErrorMessages(): Array<string> {
    const zeroErrorMessage = this.labelTextPipe.transform('DISTRIBUTION.MinimumPaymentError', 1);
    let zeroErrorMessagesCount = 0;

    if (this.distributionFonds.status !== 'INVALID' || !this.distributionFonds.touched) {
      return [];
    }

    const invalidControls = this.distributionFonds.controls.filter(c => c.valid === false);
    const validationErrors = invalidControls.map(c => ({
      error: c.errors,
      fund: c.get('fond').value as Fund
    }));

    const errorMessages = new Array<string>();
    const euroFundErrors = validationErrors.find(e => e.fund.type === FundType.Euro);
    if (euroFundErrors?.error.type === ValidationError.MaxFraction) {
      errorMessages.push(
        `${this.labelTextPipe.transform('Distribution.ValidationErrorMaxFraction1')} ${euroFundErrors.error.value}${this.labelTextPipe.transform('Distribution.ValidationErrorMaxFraction2')}`
      );
    }

    if (euroFundErrors?.error.type === ValidationError.Zero) {
      errorMessages.push(zeroErrorMessage);
      zeroErrorMessagesCount++;
    }

    const scpiFundErrors = validationErrors.filter(e => e.fund.type === FundType.SCPI);
    scpiFundErrors.forEach(f => {
      if (f.error.type === ValidationError.MinAmount) {
        const currencyValue = this.currencyPipe.transform(f.error.value, '', '', '1.2-2');
        errorMessages.push(`${this.labelTextPipe.transform('Distribution.ValidationErrorMinAmount1')} ${f.fund.name} : ${currencyValue} €`);
      }
      if (f.error.type === ValidationError.Zero && zeroErrorMessagesCount === 0) {
        errorMessages.push(zeroErrorMessage);
        zeroErrorMessagesCount++;
      }
    });

    const otherFundsError = validationErrors.find(e => e.fund.type === FundType.Other);
    if (otherFundsError) {
      const currencyValue = this.currencyPipe.transform(otherFundsError.error.value, '', '', '1.2-2');
      if (currencyValue) {
        errorMessages.push(`${this.labelTextPipe.transform('DISTRIBUTION.MinimumPaymentError', currencyValue)}`);
      } else if (zeroErrorMessagesCount === 0) {
        errorMessages.push(zeroErrorMessage);
        zeroErrorMessagesCount++;
      }
    }

    if (errorMessages.length === 0 && this.distributionFonds.errors) {
      errorMessages.push(this.distributionFonds.errors.message);
    }

    return errorMessages;
  }

  goToRecomendation() {
    this.router.navigate(['my-profile/steps'], { queryParams: { userId: this.customerService.userId }, state: { showRecommendation: true } });
  }

  async goToBeneficiaryStep() {
    await this.customerService.updateDistibutionFondsModel(this.distributionFonds.controls);
    this.router.navigate(['my-beneficiaries'], { queryParams: { userId: this.customerService.userId } });
  }

  goToFondSelection() {
    this.showRecommendationNotFollowedRappelStep = false;
    this.showRecommendationNotFollowedRepartitionStep = false;
    this.iniFondSelectionStep();
  }

  goToRappelVersements() {
    this.initRappelStep();
    this.showRecommendationNotFollowedFundStep = false;
  }

  get isAllFundsHasDistribution() {
    return this.distributionFonds.controls.every(c => Number(c.get('fraction').value) !== 0);
  }

  get isDistributionValid(): boolean {
    return !this.distributionFonds.invalid && this.isAllFundsHasDistribution;
  }

  private async initMismatchCheckStep(): Promise<void> {
    this.progressBarService.sendStep(constants.steps.myContracts.countOfTheSteps - 1);
    this.initMismatchCheckFormGroup();
    this.initControls();
    await this.restoreAnswersOnMismatchCheckStep();
  }

  async restoreAnswersOnFundsSelectionStep() {
    if (this.distributionTableValues.length > 0) {
      // The user navigated among contract tabs. Restoring selection stored locally.

      this.distributionTableValues.forEach((fDistribution: FundControlModel) => {
        this.fondsSCPI.controls.forEach((fSelection: AbstractControl) => {
          if (fSelection.value.fond.id === fDistribution.id) {
            this.selectFund(fSelection);
          }
        });

        this.fondsOther.controls.forEach((fSelection: AbstractControl) => {
          if (fSelection.value.fond.id === fDistribution.id) {
            this.selectFund(fSelection);
          }
        });

        this.fondsEuro.controls.forEach((fSelection: AbstractControl) => {
          if (fSelection.value.fond.id === fDistribution.id) {
            this.selectFund(fSelection);
          }
        });
      });
    } else if (this.userProfile.profileFunds?.length > 0) {
      // Restoring selection as stored on the remote server.
      this.userProfile.profileFunds.forEach((savedFond: ProfileFund) => {
        this.fondsSCPI.controls.forEach((f: AbstractControl) => {
          if (f.value.fond.id === savedFond.fundId) {
            this.onFondSelected(f);
          }
        });
        this.fondsOther.controls.forEach((f: AbstractControl) => {
          if (f.value.fond.id === savedFond.fundId) {
            this.onFondSelected(f);
          }
        });
        this.fondsEuro.controls.forEach((f: AbstractControl) => {
          if (f.value.fond.id === savedFond.fundId) {
            this.onFondSelected(f);
          }
        });
      });
    }
  }

  async restoreAnswersOnMismatchCheckStep() {
    if (this.userProfile) {
      if (!!this.userProfile.initialPaymentAmount && !this.userProfile.transferContractCode) {
        this.initialPaymentAmount = this.userProfile.initialPaymentAmount;
        this.initialPaymentAmountControl.patchValue(this.initialPaymentAmount);
        this.initialPaymentAmountControl.markAsRequred();
      }

      if (!!this.userProfile.scheduledPaymentAmount) {
        this.scheduledPaymentAmount = this.userProfile.scheduledPaymentAmount;
        this.reqularPaymentsControl.patchValue(this.scheduledPaymentAmount);
        this.reqularPaymentsControl.markAsRequred();
      }

      if (this.userProfile.transferContractCode && this.userProfile.socialNumberB) {
        if (this.userProfile.initialPaymentAmount) {
          // in this case initialPaymentAmount is free payment amount
          this.freePaymentAmount = this.userProfile.initialPaymentAmount;
          this.freePaymentAmountControl.patchValue(this.freePaymentAmount);
          this.freePaymentAmountControl.markAsRequred();
        }
        this.transferableContracts = await this.wsPolicesService.getContratsTransferablesFourgousRequest(this.userProfile.socialNumberB);
        this.transferableContracts?.forEach((item: ContratTransferableFourgousExtended) => {
          if (item.numeroContrat === this.userProfile.transferContractCode) {
            this.transferableContractAmount = item.valeurRachat;
            this.transferableContractAmountControl.patchValue(item.valeurRachat);
            this.transferableContractAmountControl.markAsRequred();
            this.isTransferableContractAmountTitle = `${this.labelTextPipe.transform('Distribution.TransferableContractAmountTitle1')} ${item.numeroContrat} ${this.labelTextPipe.transform(
              'Distribution.TransferableContractAmountTitle2'
            )} ${item.dateEffet} ${this.labelTextPipe.transform('Distribution.TransferableContractAmountTitle3')}`;
          }
        });
      }
    }
  }

  initControls() {
    this.initialPaymentAmountControl = this.mismatchCheckForm.get('amount') as NumberFormControl;
    this.reqularPaymentsControl = this.mismatchCheckForm.get('reqularPayments') as NumberFormControl;
    this.transferableContractAmountControl = this.mismatchCheckForm.get('transferableContractAmount') as NumberFormControl;
    this.freePaymentAmountControl = this.mismatchCheckForm.get('freePaymentAmount') as NumberFormControl;
  }

  initMismatchCheckFormGroup() {
    this.mismatchCheckForm = new FormGroup({
      amount: new NumberFormControl(false, true, null, null, false, {
        hideSuccessMark: true,
        hideErrorMessages: true,
        disabledGreenBorder: true
      }),
      reqularPayments: new NumberFormControl(false, true, null, null, false, {
        hideSuccessMark: true,
        hideErrorMessages: true,
        disabledGreenBorder: true
      }),
      transferableContractAmount: new NumberFormControl(false, true, null, null, false, {
        hideSuccessMark: true,
        hideErrorMessages: true,
        disabledGreenBorder: true
      }),
      freePaymentAmount: new NumberFormControl(false, true, null, null, false, {
        hideSuccessMark: true,
        hideErrorMessages: true,
        disabledGreenBorder: true
      })
    });
  }

  private async getFunds(): Promise<void> {
    const request = new GetReglesMetierProduitRequest({
      codeProduit: getCurrentProductType(),
      lireDocuments: false,
      idCanal: 4,
      idDemarche: 1
    });

    const response = await this.webApi.getReglesMetierProduit(request).toPromise();
    let fonds = response.produit.find(p => p.code?.trim() === getCurrentProductType()).fonds;

    fonds = this.wsReferentielMetierService.filterFundsByConfigFile(fonds) as Fonds[];

    const funds = fonds.map(f =>
      Object.assign(new Fund(), {
        id: f.id,
        type: f.typeFonds.id,
        risk: f.niveauRisque,
        name: f.libelle,
        codeISIN: f.codeISIN,
        managementCompanyName: f.gestionnaire.libelle,
        assetClass: f.classeActifs.libelle,
        zones: f.zoneGeographique.map(z => z.libelle),
        signatairePRI: f.signatairePRI,
        classificationInvestissementResponsable: f.classificationInvestissementResponsable,
        minAmount: f.getMinAmount(),
        performance: f.getFundPerformance(),
        rules: f.getFundRules(),
        estCommercialise: f.estCommercialise,
        estAffiche: f.estAffiche,
        disabled: f.estCommercialise === false && f.estAffiche === true,
        hidden: (f.estCommercialise === false && f.estAffiche === false) || (f.estCommercialise === true && f.estAffiche === false),
        feesInCaseWithdrawal: f.getFeesInCaseWithdrawal()
      })
    );

    this.addFundsToFormArray(
      this.fondsSCPI,
      funds.filter(f => f.type === FundType.SCPI)
    );

    this.addFundsToFormArray(
      this.fondsOther,
      funds.filter(f => f.type === FundType.Other)
    );

    this.addFundsToFormArray(
      this.fondsEuro,
      funds.filter(f => f.type === FundType.Euro)
    );

    this.maxEuroFundFraction = this.getMaxEuroFundFraction();
  }

  onFondSelected(fond: any) {
    if (!this.canSelectFund(fond.get('fond').value)) {
      return;
    }
    fond.controls.selected.patchValue(!fond.controls.selected.value);
    this.showRecommendationNotFollowedFundStep = false;
  }

  selectFund(fond: any) {
    if (!this.canSelectFund(fond.get('fond').value)) {
      return;
    }
    fond.controls.selected.patchValue(true);
    this.showRecommendationNotFollowedFundStep = false;
  }

  isFilterItemActive(filterObj: any, item: any) {
    if (item.id === filterObj.activeId) {
      return true;
    }

    return false;
  }

  onFilterChange(filterObj: any, id: number) {
    filterObj.activeId = id;
  }

  getPerformance(filtersObj: any, perfs: FundPerformance[]) {
    if (!perfs) {
      return '';
    }

    let value = '';

    perfs.forEach((perf: FundPerformance) => {
      if (perf.id === filtersObj.activeId) {
        value = this.performancePipe.transform(perf.rate);
      }
    });

    return value;
  }

  getSelectedFondsIds(): number[] {
    const fondsSCPIIds = this.fondsSCPI.controls
      .filter(f => f.get('selected').value === true)
      .map(f => {
        return f.get('fond').value.id;
      });
    const fondsOtherIds = this.fondsOther.controls
      .filter(f => f.get('selected').value === true)
      .map(f => {
        return f.get('fond').value.id;
      });
    const fondsEuroIds = this.fondsEuro.controls
      .filter(f => f.get('selected').value === true)
      .map(f => {
        return f.get('fond').value.id;
      });

    return [...fondsSCPIIds, ...fondsOtherIds, ...fondsEuroIds];
  }

  onValidateFondSelection(): void {
    const selectedFondsIds = this.getSelectedFondsIds();

    if (this.isRecommendationsFollowed || isManualDistributionFondsNamesEqualsRecommendet(selectedFondsIds, this.recommandation)) {
      this.initRappelStep();
    } else {
      this.showRecommendationNotFollowedFundStep = true;
    }
  }

  iniFondSelectionStep() {
    this.currentStep = Step.FUNDS_SELECTION;

    if (this.userProfile.profileFunds?.length > 0) {
      this.restoreAnswersOnFundsSelectionStep();
    }
  }

  iniRepartitionStep() {
    this.currentStep = Step.REPARTITION;
    this.restoredistributionFondsValues();
    this.userDataForViewOffer = Object.assign(new Profile(), this.customerService.updateDistibutionFondsModelWithoutSaving(this.userProfile, this.distributionFonds.controls));

    this.subscriptionHandler.subscriptions = this.distributionFonds.valueChanges.subscribe((data: any) => {
      this.userDataForViewOffer = Object.assign(new Profile(), this.customerService.updateDistibutionFondsModelWithoutSaving(this.userProfile, this.distributionFonds.controls));
    });
  }

  async initRappelStep() {
    await this.initMismatchCheckStep();

    this.currentStep = Step.RAPPEL_VERSEMENTS;
    this.distributionFonds.clear();
    this.validateFondsSelection(this.fondsSCPI);
    this.validateFondsSelection(this.fondsOther);
    this.validateFondsSelection(this.fondsEuro);
  }

  getFundRuleValidators(fund: Fund, profile: Profile): ValidatorFn[] {
    return fund.getValidators(profile, this.isCims, this.isCems, this.isPeri);
  }

  validateFondsSelection(fondsArray: FormArray): void {
    fondsArray.controls
      .filter(f => f.get('selected').value === true)
      .forEach(f => {
        const group = this.fb.group(
          {
            id: f.value.fond.id,
            busy: [false],
            fond: [f.value.fond],
            brutAmount: [null],
            netAmount: [{ value: null, disabled: true }],
            fraction: [null],
            fees: [{ value: null, disabled: true }]
          },
          { validators: (f.value.fond as Fund).getValidators(this.userProfile, this.isCims, this.isCems, this.isPeri), updateOn: 'blur' }
        );

        group.valueChanges.subscribe(async data => {
          this.showRecommendationNotFollowedRepartitionStep = false;
          const control = this.distributionFonds.controls.find(c => c.get('fond').value.id === data.fond.id);
          if (control.pristine) {
            return;
          }

          await this.updateFund(control);

          const emptyControls = this.distributionFonds.controls.filter(c => {
            let brAmount = c.get('brutAmount').value;
            return brAmount === '' || brAmount === null || isNaN(brAmount) || brAmount === 0;
          });

          if (emptyControls.length === 1) {
            if (control.value.fond.id === emptyControls[0].get('fond').value.id) {
              return;
            }

            await this.updateLastFund(emptyControls[0]);
          }
        });

        this.distributionFonds.push(group);
      });
  }

  restoredistributionFondsValues(): void {
    if (this.distributionTableValues.length > 0) {
      // The user navigated among contract tabs. Restoring distribution stored locally.
      let foundFundIds: number[] = [];
      this.distributionFonds.controls.forEach((f: AbstractControl) => {
        this.distributionTableValues.forEach((d: FundControlModel) => {
          if (f.value.fond.id == d.id) {
            //f?.patchValue({ fraction: d.fraction }, { emitEvent: false });
            f?.get('brutAmount').markAsDirty();
            f?.patchValue({ brutAmount: d.brutAmount }, { emitEvent: true });

            foundFundIds.push(f.value.fond.id);
          }
        });
      });

      this.distributionTableValues = this.distributionTableValues.filter((d: FundControlModel) => {
        if (foundFundIds.includes(d.id)) {
          return true;
        }
        return false;
      });

      this.distributionFonds.controls.forEach((f: AbstractControl) => {
        if (f.value.brutAmount == null) {
          f?.patchValue({ fraction: 0 }, { emitEvent: false });
          f?.get('brutAmount').markAsDirty();
          f?.patchValue({ brutAmount: 0 }, { emitEvent: true });
        }
      });
    } else if (this.userProfile.profileFunds?.length > 0) {
      // Restoring distribution as stored on the remote server.
      this.userProfile.profileFunds.forEach((savedFond: ProfileFund, index: number) => {
        setTimeout(() => {
          let fond = this.distributionFonds.controls.filter((f: AbstractControl) => {
            return f.value.fond.id === savedFond.fundId;
          })[0];

          // if (savedFond.portion) {
          //   fond?.patchValue({ fraction: savedFond.portion }, { emitEvent: false });
          // }

          fond?.get('brutAmount').markAsDirty();
          fond?.patchValue({ brutAmount: savedFond.amount }, { emitEvent: true });
        }, 500 * (index + 1));
      });
    }
  }

  async restoredistributionFonds(fondsArray: FormArray): Promise<void> {
    if (this.userProfile?.profileFunds?.length > 0) {
      let tempFormArray: AbstractControl[] = [];
      this.userProfile.profileFunds.forEach((item: ProfileFund) => {
        let fond = fondsArray.controls.filter((f: AbstractControl) => {
          return f.value.fond.id === item.fundId;
        })[0];

        if (fond) {
          tempFormArray.push(fond);
        }
      });

      tempFormArray.forEach(f => {
        const group = this.fb.group(
          {
            id: f.value.fond.id,
            busy: [false],
            fond: [f.value.fond],
            brutAmount: [null],
            netAmount: [{ value: null, disabled: true }],
            fraction: [null],
            fees: [{ value: null, disabled: true }]
          },
          { validators: (f.value.fond as Fund).getValidators(this.userProfile, this.isCims, this.isCems, this.isPeri), updateOn: 'blur' }
        );

        group.valueChanges.subscribe(async data => {
          const control = this.distributionFonds.controls.find(c => c.get('fond').value.id === data.fond.id);
          if (control.pristine) {
            return;
          }

          let res = await this.updateFund(control);

          const emptyControls = this.distributionFonds.controls.filter(c => {
            const brAmount = c.get('brutAmount').value;
            return brAmount === '' || brAmount === null || isNaN(brAmount);
          });

          if (emptyControls.length === 1) {
            if (control.value.fond.id === emptyControls[0].get('fond').value.id) {
              return;
            }

            await this.updateLastFund(emptyControls[0]);
          }
        });

        this.distributionFonds.push(group);
      });
    }
  }

  async updateLastFundControl(control: AbstractControl, remainders: FundControlModel, isEuro?: boolean): Promise<void> {
    let data = {
      id: remainders.id,
      brutAmount: remainders.brutAmount,
      netAmount: remainders.brutAmount,
      fraction: remainders.fraction
    };

    if (isEuro) {
      const result = await this.getEuroFundFees(remainders.fraction);
      data = Object.assign(data, { fees: result.fees });
      data.netAmount = result.netAmount;
    }

    control.patchValue(data, { emitEvent: true });
    control.markAsPristine();

    this.setRawValues(control, remainders.brutAmount, remainders.fraction);
    this.setFormValidators();
  }

  async updateLastFund(control: AbstractControl): Promise<void> {
    let isEuroFond = control.value.fond.codeISIN === this.euroFundCode;
    let remainders = this.getRemaindersFromRawValues();

    await this.updateLastFundControl(control, remainders, isEuroFond);
  }

  // Stores raw values (ie. not rounded) for computation
  setRawValues(control: AbstractControl, brutAmountRaw: number, fractionRaw: number) {
    let distributionTableRow = this.distributionTableValues.find(e => e.id === control.value.fond.id);
    if (distributionTableRow) {
      distributionTableRow.brutAmount = brutAmountRaw;
      distributionTableRow.fraction = fractionRaw;
    } else {
      this.distributionTableValues.push({
        id: control.value.fond.id,
        brutAmount: brutAmountRaw,
        fraction: fractionRaw
      });
    }
  }

  // Calculates remainders from raw (ie. not rounded) values
  getRemaindersFromRawValues(): FundControlModel {
    let brutAmountRemainder: number = 0;
    let fractionRemainder: number = 0;
    let subtotal: FundControlModel;

    subtotal = this.getSubtotalFromRawValues();

    brutAmountRemainder = this.totalAmountToDistribute - subtotal.brutAmount;
    fractionRemainder = 100 - subtotal.fraction;

    const value: FundControlModel = {
      brutAmount: brutAmountRemainder,
      fraction: fractionRemainder
    };

    return value;
  }

  getSubtotalFromRawValues(): FundControlModel {
    let subtotalBrutAmount: number = 0;
    let subtotalFraction: number = 0;

    this.distributionTableValues.forEach(r => {
      if (r.brutAmount !== undefined && !isNaN(r.brutAmount)) {
        subtotalBrutAmount += r.brutAmount;
        subtotalFraction += r.fraction;
      }
    });

    const value: FundControlModel = {
      brutAmount: subtotalBrutAmount,
      fraction: subtotalFraction
    };

    return value;
  }

  get remainsToDistibuteAmount(): number {
    return this.roundValue(this.getRemaindersFromRawValues().brutAmount, this.roundRate);
  }

  get remainsToDistibuteFraction(): number {
    return this.roundValue(this.getRemaindersFromRawValues().fraction, this.fractionRoundRate);
  }

  roundValue(value: number, fractionDigits: number): number {
    if (fractionDigits < 0) {
      throw new Error(`${this.labelTextPipe.transform('Distribution.DistributionFractionError')}`);
    }

    const fractionMultiplyer = Math.pow(10, fractionDigits);
    const res = Math.round(value * fractionMultiplyer) / fractionMultiplyer;
    return res;
  }

  async updateFundControl(control: AbstractControl, isEuro?: boolean): Promise<any> {
    const brutAmountControl = control.get('brutAmount');
    const fractionControl = control.get('fraction');
    const value: { [k: string]: any } = {};
    let fractionNotRounded: number;
    let brutAmountNotRounded: number;
    let emitEvent = false;

    if (brutAmountControl.dirty) {
      fractionNotRounded = (brutAmountControl.value / this.totalAmountToDistribute) * 100;
      const fractionRounded = this.roundValue(fractionNotRounded, this.fractionRoundRate);
      value.fraction = brutAmountControl.value === '' ? null : fractionRounded;

      value.brutAmount = typeof brutAmountControl.value === 'string' ? parseFloat(brutAmountControl.value.replace(',', '.')) : brutAmountControl.value;
      brutAmountNotRounded = value.brutAmount;

      emitEvent = true;
      brutAmountControl.markAsPristine();
    }

    if (fractionControl.dirty) {
      if (fractionControl.value === '' || fractionControl.value === undefined || isNaN(fractionControl.value)) {
        value.brutAmount = null;
      } else {
        brutAmountNotRounded = (fractionControl.value * this.totalAmountToDistribute) / 100;
        value.brutAmount = this.roundValue(brutAmountNotRounded, this.roundRate);
        value.fraction = typeof fractionControl.value === 'string' ? parseFloat(fractionControl.value.replace(',', '.')) : fractionControl.value;
        fractionNotRounded = value.fraction;
      }
    }

    value.netAmount = value.brutAmount;

    if (isEuro) {
      const feesData = await this.getEuroFundFees(fractionNotRounded);
      value.fees = feesData.fees;
      value.netAmount = feesData.netAmount;
    }

    this.setRawValues(control, brutAmountNotRounded, fractionNotRounded);
    this.setFormValidators();

    control.patchValue(value, { emitEvent });
    control.markAsPristine();
    return value;
  }

  async updateFund(control: AbstractControl): Promise<any> {
    let isEuroFond = control.value.fond.codeISIN === this.euroFundCode;

    return await this.updateFundControl(control, isEuroFond);
  }

  async getEuroFundFees(fraction: number): Promise<FundControlModel> {
    const amount = this.userProfile.isScheduledPaymentsOnly() ? this.userProfile.scheduledPaymentAmount : this.userProfile.initialPaymentAmount;

    const taxRequest = new GetFraisVersementRequest({
      transfertPacte: this.userProfile.transferContractCode ? true : false,
      numeroContrat: this.userProfile.transferContractCode ?? undefined,
      montantVerse: amount ?? 0,
      pourcentageVersementFondsEuro: Number(fraction)
    });
    const taxResponse = await this.webApi.getFraisVersement(taxRequest).toPromise();
    if (taxResponse.erreurs?.length > 0) {
      // this.bsModalService.show(AlertModalComponent, {
      //   initialState: {
      //     type: modalTypes.error,
      //     title: taxResponse.erreurs[0].codeErreur + ' ' + taxResponse.erreurs[0].libelleErreur
      //   }
      // })
      return null;
    } else {
      return {
        brutAmount: this.roundValue(Number(taxResponse.versement.montantBrutVerseFondsEuro), this.roundRate),
        netAmount: this.roundValue(Number(taxResponse.versement.montantNetVerseFondsEuro), this.roundRate),
        fees: this.roundValue(Number(taxResponse.versement.montantFraisVersementFondsEuro), this.roundRate),
        fraction: this.roundValue(Number(fraction), this.fractionRoundRate)
      } as FundControlModel;
    }
  }

  async onValidateDistribution(): Promise<void> {
    if (this.isRecommendationsFollowed || isManualDistributionEqualsRecommendet(this.distributionFonds, this.recommandation)) {
      this.goToBeneficiaryStep();
    } else {
      this.showRecommendationNotFollowedRepartitionStep = true;
    }
  }

  onOffersCountChanged(count: number): void {
    this.offersCount = count > 0 ? count : null;
  }

  private async confirmScheduledPayments(): Promise<boolean> {
    if (!this.userProfile.isScheduledPaymentsOnly()) {
      return true;
    }

    const limits = await this.wsReferentielMetierService.getScheduledPaymentAmountLimits();
    const label = (this.labelsDataService.getData('PAYMENTS.OnlyScheduledPaymentsWarning') + '') as any;
    const body = label.myFormatString([limits.minAmount.replace('.', ',')]);

    return new Promise<boolean>(res => {
      const onHideSubscription = this.bsModalService.onHide.subscribe((param: any) => {
        onHideSubscription.unsubscribe();
        res(false);
      });
      const modal = this.bsModalService.show(ConfirmationComponent, { initialState: { body }, class: 'modal-lg' });
      modal.content.onClose.subscribe((isConfirmed: boolean) => {
        onHideSubscription.unsubscribe();
        res(isConfirmed);
      });
    });
  }

  async onValidatePayments(): Promise<void> {
    // Display a warning popup when there are only scheduled payments
    const success = await this.confirmScheduledPayments();
    if (!success) {
      return;
    }

    if (history.state.mismatchCheck) {
      // case when we land on Rappel de vos versements as a first step
      this.router.navigate(['my-beneficiaries'], { queryParams: { userId: this.customerService.userId } });
      return;
    }

    if (this.isRecommendationsFollowed || (!this.isRecommendationsFollowed && this.userProfile.managementTypeCode === constants.gestionLibre.id)) {
      this.iniRepartitionStep();
    } else {
      this.showRecommendationNotFollowedRappelStep = true;
    }
  }

  goToRepartitionStep(): void {
    if (history.state.mismatchCheck) {
      // case when we land on Rappel de vos versements as a first step
      this.router.navigate(['my-beneficiaries'], { queryParams: { userId: this.customerService.userId } });
      return;
    }
    this.iniRepartitionStep();
    this.showRecommendationNotFollowedRappelStep = false;
  }

  getInitialPaymentThreshold(): number {
    const euroFundControl = this.fondsEuro.controls.find(c => c.get('fond').value.codeISIN === this.euroFundCode);
    const euroFund = euroFundControl?.get('fond').value as Fund;

    return euroFund?.getPaymentThreshold();
  }

  isUcSelectionRequired(): boolean {
    const threshold = this.getInitialPaymentThreshold();
    const isSecurityProfile = this.userProfile.isSecurityProfile();
    const isWithTransfer = this.userProfile.isWithTransfer();
    this.rule27or28ValueIssue = false; // not delete for some time, can be applied for specific cases in feature

    if (!isSecurityProfile && !isWithTransfer) {
      this.allFundsRules.forEach((item: Fonds) => {
        if (item.nomCommercial === constants.nomCommercialRuleName) {
          const fundInEuroRules = item.typeRegle[0].regle;

          const maxFraction27Value = fundInEuroRules.find((rule: Regle) => {
            return rule.code === RuleCodes.MaxFraction27;
          })?.valeur;

          const maxFraction28Value = fundInEuroRules.find((rule: Regle) => {
            return rule.code === RuleCodes.MaxFraction28;
          })?.valeur;

          this.rule27or28Value = maxFraction27Value || maxFraction28Value;

          if ((this.isPeri || this.isCems || this.isCims) && !this.rule27or28Value) {
            this.notForceToSelectUC = true;
            return;
          }

          if ((!maxFraction27Value && maxFraction28Value) || (maxFraction27Value && !maxFraction28Value)) {
            this.notForceToSelectUC = false;
            this.rule27or28ValueIssue = true;
          }

          if (!this.isPeri && maxFraction28Value === '100') {
            this.notForceToSelectUC = true;
          }
        }
      });
    }

    if (this.notForceToSelectUC) {
      return false;
    } else {
      return this.userProfile.isUcSelectionMandatory(threshold);
    }
  }

  isFundsSelectionValid(): boolean {
    const isUcRequired = this.isUcSelectionRequired();
    const isEuroFundSelected = this.fondsEuro.controls.some(c => c.get('selected').value);
    const isUcSelected = this.fondsSCPI.controls.some(c => c.get('selected').value) || this.fondsOther.controls.some(c => c.get('selected').value);

    return isUcSelected || (isEuroFundSelected && !isUcRequired);
  }

  canSelectFund(fund: Fund): boolean {
    if (!fund.minAmount) {
      return true;
    }

    const totalInitialPayment = this.userProfile.initialPaymentTotalAmount();
    const scheduledPaymentAmount = this.userProfile.scheduledPaymentTotalAmount();

    if (fund.isScpi) {
      return totalInitialPayment >= fund.minAmount;
    }

    return totalInitialPayment + scheduledPaymentAmount >= fund.minAmount;
  }

  addFundsToFormArray(fundsArray: FormArray, funds: Array<Fund>): void {
    fundsArray.clear();
    funds.forEach(f => {
      let canSelectFund = this.canSelectFund(f);
      const fondGroup = this.fb.group({
        busy: [false],
        selected: [{ value: false, disabled: !canSelectFund }],
        showSelectInput: canSelectFund,
        fond: [f]
      });

      fundsArray.push(fondGroup);
    });
  }

  async onRegulatoryDocumentClick(event: PointerEvent, control: FormControl): Promise<void> {
    try {
      event.stopPropagation();
      control.get('busy').setValue(true);
      const fundId = control.get('fond').value.id;
      const fileBlob = await this.fundsService.getFundRegulatoryDocument(fundId);
      const fileURL = URL.createObjectURL(fileBlob);
      window.open(fileURL, '_blank');
    } finally {
      control.get('busy').setValue(false);
    }
  }

  getFondTooltipText(fond: AbstractControl) {
    let min = fond.get('fond').value.minAmount;
    let name = fond.get('fond').value.name;

    return `${this.labelTextPipe.transform('Common.MinimumPaymentText1')} ${name} de ${this.euroPipe.transform(min)}`;
  }

  get subtotal(): FundControlModel {
    return this.getSubtotalFromRawValues();
  }

  private setFormValidators(): void {
    this.distributionFonds.setValidators(completeDistributionValidator(this.subtotal, this.totalAmountToDistribute, this.labelTextPipe));
    this.distributionFonds.updateValueAndValidity();
  }

  private getMaxEuroFundFraction(): number {
    const euroFund = this.fondsEuro.controls.find(c => c.value.fond.codeISIN === this.euroFundCode)?.value.fond as Fund;
    if (!euroFund) {
      return 0;
    }
    const maxFractionRuleCode = euroFund.getFractionLimitRuleCode(this.userProfile);
    const maxFraction = euroFund.rules.find(r => r.code?.trim() === maxFractionRuleCode)?.value || constants.inCaseNoCemsCimsRule27_28LimitValue;

    return Number(maxFraction);
  }

  getZonesHtml(c: any) {
    let html = '';
    let zones = c.get('fond').value.zones;

    if (zones && zones.length > 0) {
      html = zones.join(', ');
    }

    return html;
  }

  showConditionsModal(): void {
    let text = `${this.labelTextPipe.transform('Common.ContractsBonusText1')} <strong> ${this.labelTextPipe.transform('Common.ContractsBonusText2')} </strong> ${this.labelTextPipe.transform(
      'Common.ContractsBonusText3'
    )} <strong> ${this.labelTextPipe.transform('Common.ContractsBonusText4')} </strong>. ${this.labelTextPipe.transform('Common.ContractsBonusText5')}<br/>`;
    text += `${this.labelTextPipe.transform('Common.ContractsBonusText6')} <strong> ${this.labelTextPipe.transform('Common.ContractsBonusText7')}<strong> ${this.labelTextPipe.transform(
      'Common.ContractsBonusText8'
    )}</strong>. <br/> ${this.labelTextPipe.transform('Common.ContractsBonusText9')} </strong>`;
    this.bsModalService.show(AlertModalComponent, {
      initialState: {
        type: modalTypes.info,
        body: text
      },
      class: 'modal-lg'
    });
  }

  get thLabelsTooltipText(): string {
    let text = this.labelTextPipe.transform('Funds.LabelTooltipText1');
    text += `<ul><li><b> ${this.labelTextPipe.transform('Funds.LabelTooltipText2')} </b></li></ul> <p> ${this.labelTextPipe.transform('Funds.LabelTooltipText3')} <p/>`;
    text += `<ul><li><b> ${this.labelTextPipe.transform('Funds.LabelTooltipText4')} </b></li></ul> <p> ${this.labelTextPipe.transform('Funds.LabelTooltipText5')} <p/>`;
    text += `<ul><li><b> ${this.labelTextPipe.transform('Funds.LabelTooltipText6')} </b></li></ul> <p> ${this.labelTextPipe.transform('Funds.LabelTooltipText7')} <p/>`;
    return text;
  }

  get thSfdrTooltipText(): string {
    let text = this.labelTextPipe.transform('Funds.SFDRTooltipText1');
    text += `<ul><li><b> ${this.labelTextPipe.transform('Funds.SFDRTooltipText2')} </b></li></ul> <p> ${this.labelTextPipe.transform('Funds.SFDRTooltipText3')} <p/>`;
    text += `<ul><li><b> ${this.labelTextPipe.transform('Funds.SFDRTooltipText4')} </b></li></ul> <p> ${this.labelTextPipe.transform('Funds.SFDRTooltipText5')} <p/>`;
    text += `<ul><li><b> ${this.labelTextPipe.transform('Funds.SFDRTooltipText6')} </b></li></ul> <p> ${this.labelTextPipe.transform('Funds.SFDRTooltipText7')} <p/>`;
    return text;
  }
}

enum Step {
  FUNDS_SELECTION = 1,
  RAPPEL_VERSEMENTS,
  REPARTITION
}

export class FondDistribution {
  id: FormControl;
  busy: FormControl;
  fond: FormControl;
  brutAmount: FormControl;
  netAmount: FormControl;
  fraction: FormControl;
}

export class Fund {
  id: number;
  type: number;
  risk: number;
  name: string;
  codeISIN: string;
  managementCompanyName: string;
  assetClass: string;
  zones: string[];
  minAmount: number;
  performance: FundPerformance[];
  rules: FundRule[];
  estCommercialise: boolean;
  estAffiche: boolean;
  disabled: boolean;
  hidden: boolean;

  get isScpi(): boolean {
    return this.type === FundType.SCPI;
  }

  public getFractionLimitRuleCode(profile: Profile): string {
    const isSecurityProfile = profile.isSecurityProfile();
    const isWithTransfer = profile.isWithTransfer();
    const isPaymentAboveThreshold = profile.isPaymentAboveThreshold(this.getPaymentThreshold());

    if (!isSecurityProfile && !isWithTransfer) {
      return RuleCodes.MaxFraction28;
    }

    if (isSecurityProfile && !isWithTransfer && isPaymentAboveThreshold) {
      return RuleCodes.MaxFraction27;
    }

    return RuleCodes.MaxFraction26;
  }

  getValidators(profile: Profile, isCims: boolean, isCems: boolean, isPeri: boolean): ValidatorFn[] {
    const rules = new Array<ValidatorFn>();

    rules.push(zeroValidator());

    const minAmountRule = this.rules.find(r => r.code?.trim() === RuleCodes.MinAmount);
    if (minAmountRule) {
      rules.push(minAmountValidator(this.minAmount));
    }

    const fractionRuleCode = this.getFractionLimitRuleCode(profile);
    const fractionLimitRule = this.rules.find(r => r.code?.trim() === fractionRuleCode);

    // const maxFraction27Value = this.rules.find((rule: FundRule) => {
    //   return rule.code === RuleCodes.MaxFraction27;
    // })?.value;

    // const maxFraction28Value = this.rules.find((rule: FundRule) => {
    //   return rule.code === RuleCodes.MaxFraction28;
    // })?.value;

    // const rule27or28Value = maxFraction27Value || maxFraction28Value;

    if (isCims || isCems || isPeri) {
      if ((fractionRuleCode === RuleCodes.MaxFraction27 || fractionRuleCode === RuleCodes.MaxFraction28) && !fractionLimitRule) {
        rules.push(fractionLimitValidator(constants.inCaseNoCemsCimsRule27_28LimitValue));
      } else if (fractionLimitRule) {
        rules.push(fractionLimitValidator(fractionLimitRule.getValueAsNumber()));
      }
    } else if (fractionLimitRule) {
      rules.push(fractionLimitValidator(fractionLimitRule.getValueAsNumber()));
    }

    return rules;
  }

  getPaymentThreshold(): number | null {
    const rule = this.rules.find(r => r.code?.trim() === RuleCodes.PaymentThreshold);
    return rule?.getValueAsNumber();
  }
}

export function completeDistributionValidator(subtotal: FundControlModel, targetValue: number, labelTextPipe: LabelTextPipe): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formArray = control as FormArray;
    const allWithValue = formArray.controls.every(c => Number(c.get('fraction').value) !== 0);

    if ((allWithValue && subtotal.brutAmount !== targetValue) || (!allWithValue && subtotal.brutAmount > targetValue)) {
      return {
        type: ValidationError.IncompleteDistribution,
        message: `${labelTextPipe.transform('Distribution.DistributionIncompleteError')}`
      };
    }

    return null;
  };
}
