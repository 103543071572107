import { ValidatorFn } from '@angular/forms';
import { mobileValidator } from '../../validators/mobileValidator';
import { AbstractFormControl } from '../abstract-form-control';

export class MobileFormControl extends AbstractFormControl {
    constructor(
        required?: boolean,
        disabled?: boolean,
        private options?: MobileOptions
    ) {

        super(required, disabled, { updateOn: 'change' });
        this.setValue(null);
    }

    public get readOnlyCss(): boolean {
        return this.options?.readOnlyCss;
    }

    protected getValidators(): ValidatorFn[] {
        return [mobileValidator()];
    }

    get hideErrorMark(): boolean {
        return this.options?.hideErrorMark;
    }

    get hideSuccessMark(): boolean {
        return this.options?.hideSuccessMark;
    }

    get phoneTypeText(): string {
        return 'portable';
    }
}

export interface MobileOptions {
    readOnlyCss?: boolean;
    hideErrorMark?: boolean;
    hideSuccessMark?: boolean;
}

