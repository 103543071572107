import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LabelsDataService } from 'projects/mif/app/services/labels-data.service';
import { AuthService } from './auth/auth.service';
import * as version from '../../mif/environments/versions';

@Component({
  selector: 'app-root-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private labelsDataService: LabelsDataService
  ) { }

  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = false;
  isShowing = true;

  isLoggedIn: boolean;

  ngOnInit(): void {
    this.authService.isAuthenticated().subscribe(async isAuthenticated => {
      // await this.initializeServices(isAuthenticated)
      this.isLoggedIn = isAuthenticated;
    });
    console.log('version: ' + JSON.stringify(version))
  }

  private async initializeServices(isAuthenticated: boolean): Promise<void> {
    if (isAuthenticated) {
      return this.labelsDataService.initliaze();
    }
  }

  mouseenter(): void {
    this.isShowing = true;
  }

  mouseleave(): void {
    this.isShowing = false;
  }
}
