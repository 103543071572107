import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { LabelsDataService } from '../../../services/labels-data.service';
import { SubscriptionHandler } from '../../components/subscriptionHandler';
import { AbstractFormControlComponent } from '../abstract-form-control.component';
import { NirFormControl } from './nir-form-control';

@Component({
    selector: 'app-nir-form-control',
    templateUrl: './nir-form-control.component.html',
})
export class NirFormControlComponent extends AbstractFormControlComponent<NirFormControl> {
    @Input() parentForm: FormGroup;
    @Input() title: string;
    @Input() controlName: string;
    @Output() onChange: EventEmitter<any> = new EventEmitter();

    faCheck: any = faCheck;
    subscriptionHandler: SubscriptionHandler = new SubscriptionHandler();

    change(): void {
        this.onChange.emit(this.control.value);
    }

    constructor(public labelsDataService: LabelsDataService) {
        super();
    }

    requiredText: string = this.labelsDataService.getData('COMMON.PleaseFillInThisFieldText');

    public get hideSuccessMark(): boolean {
        return this.control.hideSuccessMark || false;
    }
}
