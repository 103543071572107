import { PipesModule } from '@pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { DeleteConfirmComponent } from './modals/delete-confirm/delete-confirm.component';
import { ViewOfferComponent } from './offer/view-offer/view-offer.component';
import { IAdvizeComponent } from './analytics/i-advize.component';
import { KameleoonComponent } from './kameleoon/kameleoon.component';
import { DocsModalComponent } from './modals/docs-modal/docs-modal.component';
import { ViewGpaOfferComponent } from './offer/view-gpa-offer/view-gpa-offer.component';

@NgModule({
  declarations: [
    TooltipComponent,
    SpinnerComponent,
    AlertModalComponent,
    DocsModalComponent,
    FileUploaderComponent,
    ConfirmationComponent,
    DeleteConfirmComponent,
    ViewOfferComponent,
    ViewGpaOfferComponent,
    IAdvizeComponent,
    KameleoonComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
    PipesModule,
    FontAwesomeModule,
    FileUploadModule
  ],
  exports: [
    TooltipComponent,
    SpinnerComponent,
    AlertModalComponent,
    DocsModalComponent,
    FileUploaderComponent,
    ViewOfferComponent,
    ViewGpaOfferComponent,
    IAdvizeComponent,
    KameleoonComponent
  ],
  providers: [
  ]

})

export class SharedComponentsModule { }
