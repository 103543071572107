<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field [floatLabel]="'never'" appearance="fill">
    <mat-label>{{ title }}</mat-label>
    <input matInput (ngModelChange)="change()" type="text" [class.is-invalid]="control.invalid && control.touched" class="form-control" [formControlName]="controlName" />

    <span class="success-mark" *ngIf="control.value && !control.errors && !control.hideSuccessMark">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error>
      <span *ngIf="control.errors?.required">
        {{ requiredText }}
      </span>
      <!-- <span *ngIf="control.errors?.nirValidator"> {{ 'Common.NirError' | labelText }} </span> -->
    </mat-error>
  </mat-form-field>
</div>
