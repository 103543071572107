<div class="form-group" [class.required]="isRequired" [formGroup]="parentForm">
  <mat-form-field
    [floatLabel]="'never'"
    appearance="fill"
    [class.readonly]="readOnlyCss"
  >
    <mat-label>{{ title }}</mat-label>
    <input
      matInput
      appCapitalizeAll
      (change)="change()"
      [class.is-invalid]="control.invalid && control.touched"
      class="form-control"
      [formControlName]="controlName"
      maxlength="9"
    />

    <span class="success-mark" *ngIf="control.value && !control.errors">
      <fa-icon [icon]="faCheck"></fa-icon>
    </span>

    <mat-error class="number-error">
      <span *ngIf="control.touched && control.errors">
        {{ 'SITUATION.SNCFNumberError' | labelText }}
      </span>
    </mat-error>
    
  </mat-form-field>
</div>
