import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Client, GetBICFromIBANRequest, GetBICFromIBANResponse } from '../MIF.Subscription.Parrot';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WsSepaliaService {
  constructor(private webApi: Client) {}

  private bicsData: any = {};

  public getBICFromIBANObservable(ibanNumber: string): Observable<GetBICFromIBANResponse> {
    const request = new GetBICFromIBANRequest({
      IBAN: ibanNumber
    });

    if (this.bicsData[ibanNumber]) {
      return of(this.bicsData[ibanNumber]);
    } else {
      return this.webApi.getBICFromIBAN(request).pipe(
        tap((response: GetBICFromIBANResponse) => {
          this.bicsData[ibanNumber] = response;
        })
      );
    }
  }
}
